var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"px-4 pb-4 text-caption text-sm-body-2"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('p',{staticClass:"d-flex"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v("点検日：")]),_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.result.registeredAt, "MM/DD (ddd)")))])]),_c('p',[_c('span',[_vm._v("点検結果：")]),_c('span',{class:_vm.result.isNormalForReport ? '' : _vm.ngClass},[_vm._v(_vm._s(_vm.result.isNormalForReport ? "OK" : "NG"))])])]),_c('v-spacer'),_vm._t("default")],2),_c('p',{staticClass:"d-flex"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v("送信日時：")]),_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.result.sentAt, "YYYY/MM/DD HH:mm"))+"（"+_vm._s(_vm.result.sender)+"）")])]),_c('p',{staticClass:"d-flex"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v("確認日時：")]),_c('span',[_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.result.confirmedAt, "YYYY/MM/DD HH:mm")))]),(_vm.result.confirmerName)?_c('span',[_vm._v("（"+_vm._s(_vm.result.confirmerName)+"）")]):_vm._e()])]),_c('p',{staticClass:"d-flex"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v("承認日時：")]),_c('span',[_c('span',[_vm._v(_vm._s(_vm.formatDate(_vm.result.approvedAt, "YYYY/MM/DD HH:mm")))]),(_vm.result.approverName)?_c('span',[_vm._v("（"+_vm._s(_vm.result.approverName)+"）")]):_vm._e()])])]),_c('p',{staticClass:"mb-1 pl-4 text-caption text-sm-body-2"},[_vm._v("詳細結果")]),_c('v-card',{staticClass:"pa-2 mb-4",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"transparent",attrs:{"headers":_vm.headers,"items":_vm.checkItems,"items-per-page":-1,"disable-sort":"","fixed-header":"","hide-default-header":_vm.$vuetify.breakpoint.xs,"hide-default-footer":"","mobile-breakpoint":null,"dense":""},scopedSlots:_vm._u([{key:"item.categoryName",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1)+".")]),_c('span',[_vm._v(_vm._s(item.categoryName))])]}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [(!_vm.preview)?_c('v-btn',{attrs:{"text":"","color":"primary","small":""},on:{"click":function($event){return _vm.$refs.checkMenuDialog.openDialog(item)}}},[_vm._v(" メニューを見る ")]):_vm._e()]}},(_vm.$vuetify.breakpoint.xs)?{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',{staticClass:"py-2",attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-1"},[_vm._v(_vm._s(index + 1)+".")]),_vm._v(" "+_vm._s(item.categoryName)+" ")]),_c('div',{staticClass:"pt-2 d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){return _vm.$refs.checkMenuDialog.openDialog(item)}}},[_vm._v(" メニューを見る ")]),_c('v-spacer'),_c('span',[_vm._v("結果：")]),_c('span',{class:item.checkResult == 'NG' ? _vm.ngClass : ''},[_vm._v(" "+_vm._s(item.checkResult)+" ")])],1)])])]}}:null,{key:"item.checkResult",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.checkResult == 'NG' ? _vm.ngClass : ''},[_vm._v(_vm._s(item.checkResult))])]}},{key:"top",fn:function(){return [_c('CheckMenuDialog',{ref:"checkMenuDialog"})]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }