<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <PageUnavailable v-if="$vuetify.breakpoint.xs" />
    <template v-else>
      <v-row class="px-4" no-gutters style="height: 60px">
        <v-col class="d-flex align-center">
          <span class="text-subtitle-1 text-sm-h6">メニューを分類する</span>
          <v-spacer></v-spacer>
          <div class="px-2 d-flex flex-column text-caption text--secondary">
            <span v-if="updatedAt">更新日：{{ updatedAt }}</span>
            <span v-if="updaterName">更新者：{{ updaterName }}</span>
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-card class="pa-2">
            <v-row no-gutters>
              <v-col class="pa-2" cols="12">
                <v-text-field
                  v-model="menuText"
                  label="メニューを追加"
                  hide-details
                  :append-icon="menuText ? 'mdi-plus' : ''"
                  dense
                  @keydown.enter="addMenu()"
                  @click:append="addMenu()"
                />
              </v-col>
              <v-col class="pb-2" cols="12">
                <v-card class="card-stock pa-2" outlined height="70">
                  <draggable v-model="stockMenus" draggable=".item" group="items" animation="200">
                    <v-chip
                      class="item"
                      v-for="(menu, index) in stockMenus"
                      :key="index"
                      small
                      close
                      draggable
                      @click:close="removeMenu(index)"
                    >
                      {{ menu.menuName }}
                    </v-chip>
                  </draggable>
                </v-card>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="6" sm="3">
                <v-list class="pa-0 text-body-2" :max-height="getTableHeight + 24">
                  <div v-for="(course, index) in getCourseList" :key="index">
                    <v-list-item
                      class="d-flex justify-start"
                      v-ripple="{ class: `white--text` }"
                      :style="
                        selectedIndex == index
                          ? { 'background-color': $vuetify.theme.themes.light.secondary }
                          : ''
                      "
                      @click="selectedIndex = index"
                    >
                      <div style="width: 100%">
                        <v-text-field
                          class="text-body-2"
                          v-if="editIndex == index"
                          :value="course"
                          @input="course = $event"
                          autofocus
                          hide-details
                          dense
                          @keydown.enter="updateCourse(course)"
                          @blur="updateCourse(course)"
                        />
                        <div v-else>{{ course }}</div>
                        <div v-if="index > 0 && editIndex != index" class="d-flex justify-start">
                          <v-icon class="mr-2" size="18" @click="editIndex = index">
                            mdi-pencil
                          </v-icon>
                          <v-icon size="18" @click="removeDialog = true">mdi-close-circle</v-icon>
                        </div>
                      </div>
                    </v-list-item>
                    <v-divider :key="index"></v-divider>
                  </div>
                </v-list>
                <v-text-field
                  class="px-4"
                  v-model="courseText"
                  label="コースを追加"
                  hide-details
                  :append-icon="courseText ? 'mdi-plus' : ''"
                  @keydown.enter="addCourse()"
                  @click:append="addCourse()"
                />
              </v-col>
              <v-col
                class="pa-3"
                cols="12"
                sm="9"
                :style="{ 'background-color': $vuetify.theme.themes.light.secondary }"
              >
                <v-data-table
                  :headers="headers"
                  :items="items.length > 0 ? items[selectedIndex].categories : []"
                  :items-per-page="-1"
                  :loading="loading"
                  loading-text="読込中"
                  no-data-text="データがありません"
                  disable-sort
                  fixed-header
                  hide-default-footer
                  :height="getTableHeight"
                  :mobile-breakpoint="null"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="td-category text-caption">
                        {{ item.categoryName }}
                      </td>
                      <td class="pa-2">
                        <draggable
                          v-model="item.menus"
                          draggable=".item"
                          group="items"
                          animation="200"
                        >
                          <v-chip
                            class="item"
                            v-for="(menu, index) in item.menus"
                            :key="index"
                            small
                            close-icon="mdi-arrow-up-circle"
                            draggable
                            @click:close="content.splice(index, 1)"
                          >
                            <span>{{ menu.menuName }}</span>
                            <v-btn
                              class="btn-menu"
                              icon
                              x-small
                              @click="
                                item.menus.splice(index, 1);
                                stockMenus.push(menu);
                              "
                            >
                              <v-icon size="18">mdi-arrow-up-circle</v-icon>
                            </v-btn>
                          </v-chip>
                        </draggable>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-sheet class="px-4 py-2 d-flex justify-space-between align-center">
                  <div>
                    <span class="text-caption grey--text">選択中のメニュー</span>
                    <div class="div-menu text-body-2">
                      {{ items.length > 0 ? items[selectedIndex].courseName : "" }}
                    </div>
                  </div>
                  <div>
                    <v-btn
                      class="text--secondary"
                      width="100"
                      text
                      rounded
                      :disabled="isDisabledButton"
                      @click="undoItem()"
                    >
                      キャンセル
                    </v-btn>
                    <v-btn
                      class="ml-2"
                      color="primary"
                      width="100"
                      rounded
                      depressed
                      :disabled="isDisabledButton"
                      @click="registMenu(true)"
                    >
                      保存
                    </v-btn>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <v-dialog v-model="removeDialog" persistent width="400" max-width="500">
      <v-card class="pa-2 pa-sm-4">
        <v-card-title class="pa-4">
          <span class="text-subtitle-1 text-sm-h6">以下のコースを削除します。</span>
        </v-card-title>
        <v-card-text class="px-4 pb-4">
          <p class="mb-0">
            コース名：{{ items.length > 0 ? items[selectedIndex].courseName : "" }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text--secondary" text @click="removeDialog = false">閉じる</v-btn>
          <v-btn color="#b71c1c" depressed dark @click="removeCourse()">削除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-container>
</template>

<script>
import moment from "moment";
import Draggable from "vuedraggable";
import firebase from "../../plugins/firebase";
import { db } from "../../plugins/firebase";
import { logEvent } from "../../plugins/firebase";
import dbProcess from "cumin-common/src/mixins/dbProcess";

export default {
  components: {
    Draggable,
  },
  mixins: [dbProcess],
  data: () => ({
    loading: false,
    updatedAt: "",
    updaterName: "",
    menuUID: "",
    menuText: "",
    stockMenus: [],
    courseText: "",
    selectedIndex: 0,
    editIndex: null,
    categories: [
      { categoryName: "非加熱のもの\n(冷蔵品を冷たいまま提供)", menus: [] },
      { categoryName: "加熱するもの\n(冷蔵品を加熱し熱いまま提供)", menus: [] },
      { categoryName: "常温保管するもの", menus: [] },
      { categoryName: "加熱後冷却し再加熱するもの", menus: [] },
      { categoryName: "加熱後冷却するもの", menus: [] },
    ],
    headers: [
      { text: "分類", value: "category", width: "30%" },
      { text: "メニュー", value: "menus", width: "70%" },
    ],
    items: [],
    defaultItems: {},
    removeDialog: false,
    message: "",
    messageDialog: false,
  }),
  created: function () {
    this.$emit("created");
    this.loadList();
  },
  computed: {
    /**
     * テーブルの高さを取得
     * @return {number} 高さ
     */
    getTableHeight() {
      const height = this.$vuetify.breakpoint.height;
      if (height <= 500) return 500;
      return height - 414;
    },

    /**
     * コース一覧を取得
     * @return {array} コース一覧
     */
    getCourseList() {
      const list = [];
      for (const item of this.items) {
        list.push(item.courseName);
      }
      return list;
    },

    /**
     * 登録、クリアボタンの活性判定
     * @return {boolean} 判定結果
     */
    isDisabledButton() {
      if (JSON.stringify(this.defaultItems.items) != JSON.stringify(this.items)) return false;
      return true;
    },
  },
  methods: {
    /**
     * DBからメニューを取得
     */
    async loadList() {
      this.loading = true;
      const selectedIndex = this.selectedIndex;
      const shop = this.$store.getters.getShop;
      this.items = [];
      const courses = [];
      const categories = [];

      // DBからメニューを取得
      const menus = await db
        .collection("menus")
        .where("shopUID", "==", shop.shopUID)
        .get()
        .catch((error) => {
          logEvent("error_db_read", {
            method_name: "loadList",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      // 既存メニューがある場合
      if (!menus.empty) {
        this.menuUID = menus.docs[0].id;
        this.stockMenus = [...menus.docs[0].data().stockMenus];
        categories.push(menus.docs[0].data().category01);
        categories.push(menus.docs[0].data().category02);
        categories.push(menus.docs[0].data().category03);
        categories.push(menus.docs[0].data().category04);
        categories.push(menus.docs[0].data().category05);

        // コース名を取得
        categories[0].courses.forEach((course) => {
          courses.push(course.courseName);
        });

        // テーブル作成
        courses.forEach((course, courseIndex) => {
          const categoryItems = [];
          categories.forEach((category) => {
            const filterItem = category.courses.filter((item, index) => index == courseIndex)[0];
            categoryItems.push({
              categoryName: category.categoryName,
              menus: filterItem.menus,
            });
          });
          this.items.push({
            courseName: course,
            categories: categoryItems,
          });
        });

        this.updatedAt = moment(menus.docs[0].data().updatedAt.seconds * 1000).format("YYYY/MM/DD");
        this.updaterName = menus.docs[0].data().updaterName;
      } else {
        this.items.push({
          courseName: "通常メニュー",
          categories: JSON.parse(JSON.stringify(this.categories)),
        });
        this.stockMenus = [];
        this.updatedAt = "";
        this.updaterName = "";
      }

      // 初期情報を保存しておく
      this.saveDefault();

      this.selectedIndex = selectedIndex;
      this.loading = false;
    },

    /**
     * 編集情報を保存しておく
     */
    saveDefault() {
      this.defaultItems.items = JSON.parse(JSON.stringify(this.items));
      this.defaultItems.stockMenus = JSON.parse(JSON.stringify(this.stockMenus));
    },

    /**
     * メニューの追加
     */
    addMenu() {
      if (this.menuText) {
        this.stockMenus.push({ menuName: this.menuText, remarks: "" });
        this.menuText = "";
        this.saveDefault();
        this.registMenu(false);
      }
    },

    /**
     * メニューの削除
     * @param {number} index
     */
    removeMenu(index) {
      this.stockMenus.splice(index, 1);
      this.saveDefault();
      this.registMenu(false);
    },

    /**
     * コースの追加
     */
    addCourse() {
      if (this.courseText) {
        this.items.push({
          courseName: this.courseText,
          categories: JSON.parse(JSON.stringify(this.categories)),
        });
        this.courseText = "";
        this.saveDefault();
        this.registMenu(false);
      }
    },

    /**
     * コース名の編集
     */
    updateCourse(text) {
      this.editIndex = null;
      if (text && this.items[this.selectedIndex].courseName != text) {
        this.items[this.selectedIndex].courseName = text;
        this.saveDefault();
        this.registMenu(false);
      }
    },

    /**
     * コースの削除
     */
    removeCourse() {
      this.items.splice(this.selectedIndex, 1);
      this.removeDialog = false;
      this.saveDefault();
      this.registMenu(false);
      this.selectedIndex = this.selectedIndex - 1;
    },

    /**
     * メニューリストを元に戻す
     */
    undoItem() {
      this.items = JSON.parse(JSON.stringify(this.defaultItems.items));
      this.stockMenus = JSON.parse(JSON.stringify(this.defaultItems.stockMenus));
    },

    /**
     * メニューリストをDBに登録
     */
    async registMenu(isOpendialog) {
      // オフライン時の処理
      if (!navigator.onLine) return this.$refs.sendErrorDialog.open("offline");

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      const registId = this.menuUID || this.createDocId("menus");
      const registData = {
        shopUID: shop.shopUID,
        stockMenus: JSON.parse(JSON.stringify(this.stockMenus)),
        updatedAt: serverTimestamp,
        updaterName: user.name,
      };
      this.categories.forEach((category, index) => {
        const courses = this.items.map((item) => ({
          courseName: item.courseName,
          menus: item.categories[index].menus,
        }));

        registData["category0" + (index + 1)] = {
          categoryName: category.categoryName,
          courses: courses,
        };
      });

      // 新規登録の場合
      if (!this.menuUID) registData.createdAt = serverTimestamp;

      const result = await this.writeTransaction([
        {
          method: this.menuUID ? "update" : "set",
          collection: "menus",
          docId: registId,
          data: registData,
        },
      ]);

      // 送信失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        return;
      }

      if (!this.menuUID) this.menuUID = registId;

      if (isOpendialog) {
        this.loadList();
        this.message = "登録しました。";
        this.messageDialog = true;
      } else {
        this.updatedAt = moment().format("YYYY/MM/DD");
        this.updaterName = user.name;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-stock {
  overflow-y: auto;
}

.btn-menu {
  margin-left: 2px;
  margin-right: -8px;
}

.td-category {
  white-space: pre;
  border-right: solid 1px silver;
}

::v-deep .v-list {
  overflow: auto !important;
}

.v-list-item {
  min-height: 56px !important;
}

.v-list-item:before {
  background-color: unset;
}

.div-menu {
  max-width: calc(62vw - 200px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
