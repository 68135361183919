<template>
  <div>
    <div class="px-4 pb-4 text-caption text-sm-body-2">
      <div class="d-flex">
        <div>
          <p class="d-flex">
            <span class="text-no-wrap">点検日：</span>
            <span>{{ formatDate(result.registeredAt, "MM/DD (ddd)") }}</span>
          </p>
          <p>
            <span>点検結果：</span>
            <span :class="result.isNormalForReport ? '' : ngClass">{{
              result.isNormalForReport ? "OK" : "NG"
            }}</span>
          </p>
        </div>
        <v-spacer></v-spacer>
        <slot />
      </div>
      <p class="d-flex">
        <span class="text-no-wrap">送信日時：</span>
        <span>{{ formatDate(result.sentAt, "YYYY/MM/DD HH:mm") }}（{{ result.sender }}）</span>
      </p>
      <p class="d-flex">
        <span class="text-no-wrap">確認日時：</span>
        <span>
          <span>{{ formatDate(result.confirmedAt, "YYYY/MM/DD HH:mm") }}</span>
          <span v-if="result.confirmerName">（{{ result.confirmerName }}）</span>
        </span>
      </p>
      <p class="d-flex">
        <span class="text-no-wrap">承認日時：</span>
        <span>
          <span>{{ formatDate(result.approvedAt, "YYYY/MM/DD HH:mm") }}</span>
          <span v-if="result.approverName">（{{ result.approverName }}）</span>
        </span>
      </p>
    </div>

    <p class="mb-1 pl-4 text-caption text-sm-body-2">詳細結果</p>
    <v-card class="pa-2 mb-4" outlined>
      <v-data-table
        class="transparent"
        :headers="headers"
        :items="checkItems"
        :items-per-page="-1"
        disable-sort
        fixed-header
        :hide-default-header="$vuetify.breakpoint.xs"
        hide-default-footer
        :mobile-breakpoint="null"
        dense
      >
        <template v-slot:[`item.categoryName`]="{ item, index }">
          <span>{{ index + 1 }}.</span>
          <span>{{ item.categoryName }}</span>
        </template>
        <template v-slot:[`item.detail`]="{ item }">
          <v-btn
            v-if="!preview"
            text
            color="primary"
            small
            @click="$refs.checkMenuDialog.openDialog(item)"
          >
            メニューを見る
          </v-btn>
        </template>
        <template v-if="$vuetify.breakpoint.xs" v-slot:item="{ item, index }">
          <tr>
            <td class="py-2" :colspan="headers.length">
              <div class="d-flex">
                <div class="mr-1">{{ index + 1 }}.</div>
                {{ item.categoryName }}
              </div>
              <div class="pt-2 d-flex align-center">
                <v-btn color="primary" text small @click="$refs.checkMenuDialog.openDialog(item)">
                  メニューを見る
                </v-btn>
                <v-spacer></v-spacer>
                <span>結果：</span>
                <span :class="item.checkResult == 'NG' ? ngClass : ''">
                  {{ item.checkResult }}
                </span>
              </div>
            </td>
          </tr>
        </template>
        <template v-slot:[`item.checkResult`]="{ item }">
          <span :class="item.checkResult == 'NG' ? ngClass : ''">{{ item.checkResult }}</span>
        </template>
        <template v-slot:top>
          <CheckMenuDialog ref="checkMenuDialog" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import calcDate from "cumin-common/src/mixins/calcDate";
import CheckMenuDialog from "../organisms/CheckMenuDialog";

export default {
  props: {
    result: Object,
    preview: Boolean,
  },
  components: {
    CheckMenuDialog,
  },
  mixins: [calcDate],
  data: () => ({
    headers: [
      { text: "分類", value: "categoryName" },
      { text: "", value: "detail", width: 150, align: "center" },
      { text: "点検結果", value: "checkResult", width: 100, align: "center" },
    ],
    ngClass: "red--text font-weight-bold",
  }),
  computed: {
    /**
     * 点検内容の取得
     * @return {array} 点検内容
     */
    checkItems() {
      return new Array(5).fill().map((_, i) => this.result[`category0${i + 1}`]);
    },
  },
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0;
}

::v-deep .v-data-table__wrapper table thead tr th {
  padding: 0 8px;
  height: 42px !important;
}
::v-deep .v-data-table__wrapper table tbody tr td {
  padding: 0 8px;
}

.v-data-table ::v-deep .v-data-table__wrapper table .v-data-table-header-mobile {
  display: none;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row {
  min-height: 32px;
  font-size: 12px;
}

.v-data-table ::v-deep .v-data-table__wrapper table tbody tr .v-data-table__mobile-row__header {
  font-weight: 400;
  white-space: nowrap;
}
</style>
