import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";
import store from "../store/index";
import moment from "moment";

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(config);

firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.NONE)
  .then(() => {
    //
  })
  .catch((error) => {
    console.log(error.code);
    console.log(error.message);
  });

export const db = firebase.firestore();

const storage = firebase.storage();
storage.setMaxUploadRetryTime(2000);
export const storageRef = storage.ref();

export const analytics = process.env.NODE_ENV == "test" ? null : firebase.analytics();
// eslint-disable-next-line @typescript-eslint/ban-types
export function logEvent(eventName: string, addProperty: object) {
  if (location.href.match("localhost")) return;
  console.log(eventName);
  const user = store.getters.getUser;
  const shop = store.getters.getShop;
  const property = {
    event_time: moment().format("YYYY/MM/DD HH:mm:ss.SSS"),
    app_name: "garnet",
    user_uid: user.userUID,
    shop_uid: shop.shopUID,
  };

  if (addProperty != undefined) Object.assign(property, addProperty);
  firebase.analytics().logEvent(eventName, property);

  if ("error_message" in property) {
    const environment = process.env.NODE_ENV == "development" ? "[DEV]" : "";
    const url = location.href;
    const functions = firebase.app().functions("asia-northeast1");
    const sendErrorEventMail = functions.httpsCallable("sendErrorEventMail");
    sendErrorEventMail(Object.assign(property, { environment, url }));
  }
}

export default firebase;
