<template>
  <v-dialog v-model="dialog" width="auto ">
    <v-card class="pa-2 pa-sm-4" color="#f5f5f5">
      <v-card-title class="pa-4 d-flex flex-nowrap">
        <div class="text-subtitle-1 text-sm-h6">{{ item.categoryName }}</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-row no-gutters>
        <v-col cols="12" :sm="courses.length == 1 ? 12 : 6">
          <v-card
            class="mb-1"
            v-for="course in $vuetify.breakpoint.smAndUp
              ? courses.filter((_, i) => i % 2 === 0)
              : courses"
            :key="course.courseName"
            outlined
            width="480"
          >
            <v-card-title class="text-body-1">{{ course.courseName }}</v-card-title>
            <v-card-text class="text-caption">
              <span v-if="course.menus.length == 0">無し</span>
              <div v-else>
                <span v-for="(menu, index) in course.menus" :key="index">
                  {{ menu.menuName }}<span v-if="course.menus.length > index + 1">、</span>
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.smAndUp && courses.length > 1" sm="6">
          <v-card
            class="ml-1 mb-1"
            v-for="course in courses.filter((_, i) => i % 2 !== 0)"
            :key="course.courseName"
            outlined
            width="480"
          >
            <v-card-title class="text-body-1">{{ course.courseName }}</v-card-title>
            <v-card-text class="text-caption">
              <span v-if="course.menus.length == 0">無し</span>
              <div v-else>
                <span v-for="(menu, index) in course.menus" :key="index">
                  {{ menu.menuName }}<span v-if="course.menus.length > index + 1">、</span>
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    item: {},
    courses: [],
  }),
  methods: {
    /**
     * ダイアログを開く
     * @param {Object} selectedItem 選択した行データ
     */
    openDialog(selectedItem) {
      this.item = selectedItem;
      this.courses = selectedItem.courses.slice();
      this.dialog = true;
    },
  },
};
</script>
