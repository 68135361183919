<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col cols="4" align-self="center">
        <span class="text-subtitle-1 text-sm-h6">点検結果一覧</span>
      </v-col>
      <v-col cols="5" sm="4" align-self="center">
        <MenuMonthPicker :month="month" @update="updateMonth" />
      </v-col>
    </v-row>

    <!-- 点検結果一覧 -->
    <v-row class="mb-4" no-gutters>
      <v-col>
        <TableSummary
          :selected.sync="selected"
          :items="items"
          :headers="$vuetify.breakpoint.xs ? xsHeaders : headers"
          :filterHeader="{}"
          :comments="comments"
          :loading="loading"
          sortBy="registeredAt"
          :sortDesc="true"
          @clickRow="$refs.checkResultDialog.openDialog($event)"
        >
          <template #[`item.registeredAt`]="{ item }">
            <td>
              {{ formatDate(item.registeredAt, "MM/DD (ddd)") }}
              <v-icon
                v-if="
                  $vuetify.breakpoint.smAndUp &&
                  comments.some(
                    (e) => e.relationCheckResult && e.relationCheckResult.uid == item.id
                  )
                "
                class="ml-1"
              >
                mdi-comment-processing-outline
              </v-icon>
            </td>
          </template>
          <template v-for="i in 5" #[`item.category0${i}`]="{ item }">
            <td class="text-center" :key="i">
              <span :class="item[`category0${i}`].checkResult == 'NG' ? ngClass : ''">
                {{ item[`category0${i}`].checkResult }}
              </span>
            </td>
          </template>
        </TableSummary>
      </v-col>
    </v-row>

    <!-- 特記事項・承認ボタン -->
    <div class="d-sm-flex align-center">
      <CardComments
        ref="comments"
        :date="month"
        :isMonthly="true"
        serviceName="重要管理"
        @load="comments = $event"
      />
      <ButtonApproveResult
        :items="selected"
        :disabled="selected.length == 0"
        collection="criticalControlResults"
        @reload="loadList()"
      />
    </div>

    <CheckResultDialog
      ref="checkResultDialog"
      :comments="comments"
      @update="$refs.comments.loadComment()"
    />
  </v-container>
</template>

<script>
import { db } from "../../plugins/firebase";
import moment from "moment";
import calcDate from "cumin-common/src/mixins/calcDate";
import CheckResultDialog from "../organisms/CheckResultDialog";

export default {
  components: {
    CheckResultDialog,
  },
  mixins: [calcDate],
  data: () => ({
    loading: false,
    month: "",
    selected: [],
    items: [],
    headers: [
      { text: "点検日", value: "registeredAt", width: 120 },
      { text: "1", value: "category01", align: "center", sortable: false },
      { text: "2", value: "category02", align: "center", sortable: false },
      { text: "3", value: "category03", align: "center", sortable: false },
      { text: "4", value: "category04", align: "center", sortable: false },
      { text: "5", value: "category05", align: "center", sortable: false },
      { text: "送信日時", value: "sentAt", width: 100 },
      { text: "確認日時", value: "confirmedAt", width: 100 },
      { text: "承認日時", value: "approvedAt", width: 112 },
    ],
    xsHeaders: [
      { text: "点検日", value: "registeredAt", width: 100, class: "responsive" },
      { text: "結果", value: "isNormalForReport", width: 58 },
      { text: "送信", value: "sentAt", width: 44 },
      { text: "確認", value: "confirmedAt", align: "center", width: 34 },
      { text: "承認", value: "approvedAt", align: "center", width: 46 },
    ],
    ngClass: "red--text font-weight-bold",
    comments: [],
  }),
  created: function () {
    this.$emit("created");
    const queryDate = this.$route.query.date;
    this.month = queryDate ? queryDate.substr(0, 7) : moment().format("YYYY/MM");
  },
  activated: async function () {
    await this.loadList();
  },
  methods: {
    /**
     * DBから点検結果一覧を取得
     */
    async loadList() {
      this.loading = true;
      this.items = [];
      const shop = this.$store.getters.getShop;
      const startAt = new Date(this.month + "/01 00:00:00");
      const endAt = new Date(moment(startAt).add(1, "month").add(-1, "seconds"));

      await db
        .collection("criticalControlResults")
        .where("shopUID", "==", shop.shopUID)
        .orderBy("registeredAt", "asc")
        .startAt(startAt)
        .endAt(endAt)
        .get()
        .then((CheckResults) => {
          CheckResults.forEach((CheckResult) => {
            this.items.push(CheckResult.data());
            this.items[this.items.length - 1].id = CheckResult.id;
          });
        })
        .catch((error) => {
          console.log("点検結果の取得に失敗しました。：" + error.message);
          this.logEvent("error_db_read", {
            method_name: "loadList",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      this.loading = false;
      this.selected = [];
    },

    /**
     * 月変更があったら点検結果再読み込み
     * @param {string} month YYYY/MM
     */
    async updateMonth(month) {
      this.month = month;
      await this.loadList();
      this.$refs.comments.loadComment();
    },
  },
};
</script>
