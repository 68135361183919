import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueHead from "vue-head";
import axios from "axios";
import common from "cumin-common";
import logEvent from "cumin-common/src/mixins/logEvent";

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

Vue.use(VueHead, {
  separator: "",
});

Vue.use(common);

Vue.mixin(logEvent);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
