<template>
  <v-container class="pa-2 pa-sm-4" fluid style="height: 100%">
    <v-row class="px-4" no-gutters style="height: 60px">
      <v-col align-self="center">
        <span class="mr-5 text-subtitle-1 text-sm-h6">重要管理点検票</span>
        <span class="text-caption text-sm-subtitle-1 text--secondary">{{ businessDate }}</span>
      </v-col>
    </v-row>

    <!-- 点検票 -->
    <v-row class="mb-4" no-gutters>
      <v-col>
        <v-card>
          <v-data-table
            :headers="$vuetify.breakpoint.xs ? xsHeaders : headers"
            :items="items"
            :items-per-page="-1"
            :loading="loading"
            loading-text="読込中"
            no-data-text="メニューを登録してください"
            disable-sort
            fixed-header
            hide-default-footer
            :mobile-breakpoint="null"
            :height="getTableHeight"
          >
            <template v-slot:[`header.checkResult`]="{}">
              <div class="d-flex justify-space-between align-center">
                <span></span>
                <span>点検結果</span>
                <MenuComment :item="commentItem" targetKey="title" />
              </div>
            </template>
            <template v-slot:item="{ item, index }">
              <!-- 横スマホ・タブレット・PC -->
              <tr
                v-if="$vuetify.breakpoint.smAndUp"
                :class="item.checkResult != 'NG' ? 'white' : 'red lighten-5'"
              >
                <td>
                  <p class="mb-0 text-start">{{ index + 1 }}. {{ item.categoryName }}</p>
                  <p
                    class="mb-0 text-caption grey--text text-truncate"
                    :style="{ 'max-width': getMenuWidth }"
                  >
                    <span v-if="mergedMenus[index] == ''">無し</span>
                    <span v-else>{{ mergedMenus[index] }}</span>
                  </p>
                </td>
                <td>
                  <v-btn color="primary" text small @click="$refs.checkMenuDialog.openDialog(item)">
                    メニューを見る
                  </v-btn>
                </td>
                <td>
                  <ButtonCheckResult
                    :clearable="false"
                    :disabled="buttonText == '確認済'"
                    :value="item.checkResult"
                    @input="items.splice(index, 1, { ...item, checkResult: $event })"
                  />
                </td>
              </tr>

              <!-- 縦スマホ -->
              <tr v-else :class="item.checkResult != 'NG' ? 'white' : 'red lighten-5'">
                <td class="py-2" :colspan="xsHeaders.length">
                  <p class="mb-0 text-start">{{ index + 1 }}. {{ item.categoryName }}</p>
                  <p
                    class="mb-0 text-caption grey--text text-truncate"
                    :style="{ 'max-width': getMenuWidth }"
                  >
                    <span v-if="mergedMenus[index] == ''">無し</span>
                    <span v-else>{{ mergedMenus[index] }}</span>
                  </p>
                  <div class="pt-2 d-flex">
                    <v-btn
                      color="primary"
                      text
                      small
                      @click="$refs.checkMenuDialog.openDialog(item)"
                    >
                      メニューを見る
                    </v-btn>
                    <v-spacer></v-spacer>
                    <ButtonCheckResult
                      :clearable="false"
                      :disabled="buttonText == '確認済'"
                      :value="item.checkResult"
                      @input="items.splice(index, 1, { ...item, checkResult: $event })"
                    />
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:top>
              <CheckMenuDialog ref="checkMenuDialog" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- 特記事項・送信ボタン -->
    <div class="d-sm-flex align-center" no-gutters>
      <CardComments
        ref="comments"
        :date="businessDate"
        :isMonthly="false"
        serviceName="重要管理"
        @load="existsComment = $event.length > 0"
      />
      <ButtonSendResult
        :loading="sending"
        :isNormal="items.every((item) => item.checkResult != 'NG')"
        :disabled="sending || buttonText == '確認済'"
        :buttonText="buttonText"
        @click="sendCheckResult()"
      />
    </div>

    <DialogSendError ref="sendErrorDialog" />
    <DialogMessage :dialog="messageDialog" :message="message" @close="messageDialog = false" />
  </v-container>
</template>

<script>
import { db } from "../../plugins/firebase";
import firebase from "../../plugins/firebase";
import moment from "moment";
import calcDate from "cumin-common/src/mixins/calcDate";
import dbProcess from "cumin-common/src/mixins/dbProcess";
import uploadStorage from "cumin-common/src/mixins/uploadStorage";
import CheckMenuDialog from "../organisms/CheckMenuDialog";

export default {
  components: {
    CheckMenuDialog,
  },
  mixins: [calcDate, dbProcess, uploadStorage],
  data: () => ({
    timerID: null,
    loading: false,
    businessDate: "",
    buttonText: "",
    headers: [
      { text: "分類", value: "category" },
      { text: "", value: "detail", width: 120 },
      { text: "点検結果", value: "checkResult", width: 160 },
    ],
    xsHeaders: [
      { text: "点検項目", value: "questionContent" },
      { text: "点検結果", value: "checkResult", width: 108 },
    ],
    items: [],
    existsComment: false,
    commentItem: { comment: "", title: "この点検票" },
    sentResult: {},
    sentResultUID: "",
    selectItem: {},
    mergedMenus: [],
    menuDialog: false,
    sending: false,
    message: "",
    messageDialog: false,
  }),
  created: function () {
    this.$emit("created");
    this.logEvent("app_connect");

    this.businessDate = this.calculateBusinessDate(new Date());
  },
  activated: async function () {
    this.$emit("created");
    await this.loadList();
    this.$nextTick(() => this.$refs.comments.loadComment());

    const setDate = () => (this.businessDate = this.calculateBusinessDate(new Date()));
    this.timerID = setInterval(setDate, 60000);
  },
  deactivated: function () {
    clearInterval(this.timerID);
  },
  computed: {
    /**
     * テーブルの高さを取得
     * @return {number} 高さ
     */
    getTableHeight() {
      const bp = this.$vuetify.breakpoint;
      const height = bp.height - (bp.xs ? 212 : 204);
      const offset = this.existsComment ? 186 : 60;
      return height <= 300 ? 300 : height - offset;
    },

    /**
     * メニュー表示幅の取得
     * @return {number} 高さ
     */
    getMenuWidth() {
      const width = this.$vuetify.breakpoint.width - 17;
      const offset = this.$vuetify.breakpoint.xs ? 48 : 352;
      return width - offset + "px";
    },
  },
  methods: {
    /**
     * DBから点検項目を取得
     */
    async loadList() {
      this.loading = true;
      const shop = this.$store.getters.getShop;
      const date = new Date(this.businessDate + " 00:00:00");
      this.items = [];

      // 送信済の点検結果を取得
      const results = await db
        .collection("criticalControlResults")
        .where("shopUID", "==", shop.shopUID)
        .orderBy("registeredAt", "asc")
        .startAt(date)
        .get()
        .catch((error) => {
          this.logEvent("error_db_read", {
            method_name: "loadList",
            error_message: error.message,
          });
          this.$router.replace({
            name: "SystemError",
            params: { 0: location.pathname, error: error.message },
          });
        });

      if (!results.empty) {
        const result = results.docs[0].data();
        this.sentResult = JSON.parse(JSON.stringify(result));
        this.sentResultUID = results.docs[0].id;
        for (let index = 1; index <= 5; index++) {
          this.items.push(JSON.parse(JSON.stringify(result["category0" + index])));
        }

        if (result.confirmerName != "") {
          this.buttonText = "確認済";
          this.isDisabled = true;
        } else {
          this.buttonText = "";
        }
      } else {
        // DBからメニューを取得
        await db
          .collection("menus")
          .where("shopUID", "==", shop.shopUID)
          .get()
          .then((menus) => {
            if (menus.empty) return;
            const menu = menus.docs[0].data();
            for (let index = 1; index <= 5; index++) {
              this.items.push(JSON.parse(JSON.stringify(menu["category0" + index])));
            }
          })
          .catch((error) => {
            this.logEvent("error_db_read", {
              method_name: "loadList",
              error_message: error.message,
            });
            this.$router.replace({
              name: "SystemError",
              params: { 0: location.pathname, error: error.message },
            });
          });

        this.buttonText = "";
      }

      // 分類別メニューを結合
      this.items.forEach((item, index) => {
        let mergedMenu = [];
        item.courses.forEach((course) => {
          course.menus.forEach((menu) => {
            mergedMenu.push(menu.menuName);
          });
        });
        this.mergedMenus[index] = mergedMenu.join("、");
        item.checkResult = "OK";
      });

      this.loading = false;
    },

    clickRow(item) {
      this.selectItem = JSON.parse(JSON.stringify(item));
      this.menuDialog = true;
      this.$nextTick(function () {
        const elements = document.getElementsByClassName("v-dialog--active");
        elements[0].scrollTop = 0;
      });
    },

    /**
     * 点検結果送信
     */
    async sendCheckResult() {
      // オフライン時の処理
      if (!navigator.onLine) return this.$refs.sendErrorDialog.open("offline");

      this.sending = true;

      const user = this.$store.getters.getUser;
      const shop = this.$store.getters.getShop;
      const sendDate = new Date(this.businessDate + " 00:00:00");
      const registeredAt = firebase.firestore.Timestamp.fromDate(sendDate);
      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

      const isUpdate = !!this.sentResultUID;
      const isNormalForReport = !this.items.some((item) => item.checkResult == "NG");

      // 送信データ作成
      const resultId = this.sentResultUID || this.createDocId("questionAnswers");
      const sendData = {
        shopUID: shop.shopUID,
        userUID: user.userUID,
        isNormalForReport,
        registeredAt,
        sentAt: serverTimestamp,
        sender: user.name,
        confirmedAt: "",
        confirmerName: "",
        approvedAt: "",
        approverName: "",
        updatedAt: serverTimestamp,
      };
      for (let index = 0; index < 5; index++) {
        sendData["category0" + (index + 1)] = JSON.parse(JSON.stringify(this.items[index]));
      }

      // 新規送信の場合
      if (!isUpdate) sendData.createdAt = serverTimestamp;

      const writeItems = [
        {
          method: isUpdate ? "update" : "set",
          collection: "criticalControlResults",
          docId: resultId,
          data: sendData,
        },
      ];

      // 特記事項がある場合
      if (this.commentItem.comment || this.commentItem.imgFileUrl) {
        const commentId = this.createDocId("comments");
        const sendCommentData = {
          name: user.name,
          registrantUID: user.userUID,
          shopUID: shop.shopUID,
          position: user.position,
          content: this.commentItem.comment || "",
          serviceName: "重要管理",
          relationCheckResult: {
            uid: resultId,
            title: moment().format("YYYY/MM/DD"),
            isNormal: isNormalForReport,
            sentAt: moment().format("MM/DD HH:mm"),
          },
          registeredAt,
          createdAt: serverTimestamp,
          updatedAt: serverTimestamp,
        };

        // 画像ありの場合、画像をアップロードする
        if (this.commentItem.compressedFile) {
          const commentDate = this.businessDate.replace(/\//g, "-");
          const path = `commentsFile/${shop.shopUID}/${commentDate}/${commentId}.jpg`;
          const uploadResult = await this.uploadFile({
            path,
            file: this.commentItem.compressedFile,
          });

          // 送信失敗の場合
          if (uploadResult.status == "error") {
            const type =
              uploadResult.code === "storage/retry-limit-exceeded" ? "unstable" : "unexpected";
            this.$refs.sendErrorDialog.open(type);
            this.sending = false;
            return;
          }

          sendCommentData.imgFileURL = uploadResult.url;
        }

        writeItems.push({
          method: "set",
          collection: "comments",
          docId: commentId,
          data: sendCommentData,
        });
      }

      // DB送信
      const result = await this.writeTransaction(writeItems);

      // 送信失敗の場合
      if (result.status !== "success") {
        const type = result.message == "Connection failed." ? "unstable" : "unexpected";
        this.$refs.sendErrorDialog.open(type);
        this.logEvent("send_check_list", { message: "点検票の送信に失敗しました。" });
        return (this.sending = false);
      }

      if (!isUpdate) this.sentResultUID = resultId;

      // 特記事項リストの更新
      this.$refs.comments.loadComment();
      this.commentItem = { comment: "", title: "この点検票" };

      this.message = "送信しました。";
      this.messageDialog = true;
      this.logEvent("send_check_list", { message: this.message });

      this.sending = false;

      // NGメールの送信
      if (!isNormalForReport) await this.sendNgMail();
    },

    /**
     * 点検結果にNGがあった場合、メール送信
     */
    async sendNgMail() {
      const shop = this.$store.getters.getShop;
      let environment = "";
      if (process.env.NODE_ENV == "test") environment = "[DEV]";
      if (process.env.NODE_ENV == "development") environment = "[DEV]";
      if (process.env.NODE_ENV == "staging") environment = "[STG]";

      const functions = await firebase.app().functions("asia-northeast1");
      const sendMail = functions.httpsCallable("sendMail");

      const subject =
        shop.shopUID == this.$route.params.shopUID
          ? `${environment}[ハレコード] 重要管理点検結果NGのお知らせ`
          : `${environment}[ハレコード][${shop.selectShopName}] 重要管理点検結果NGのお知らせ`;

      const mailResult = await sendMail({
        position: this.$store.state.user.position,
        shopUID: this.$store.state.shop.shopUID,
        subject: subject,
        text:
          "各位\n\n" +
          "重要管理の点検結果にNGがありましたのでお知らせいたします。\n\n" +
          "【店舗名】\n" +
          "　" +
          `${shop.selectShopName}\n\n\n` +
          "以下のURLからログインして内容のご確認をお願いいたします。\n" +
          `${window.location.origin}/${shop.shopUID}/check-summary\n\n` +
          "※このメールは送信専用のため、ご返信いただけません。\n\n\n" +
          "－－－－－－－－－－－－－－－－－－－－－－－－－－－－－－\n" +
          "ハレコードは株式会社ウエノフードテクノの商標です。\n",
      });

      if (mailResult.data.status == "error") {
        this.logEvent("error_function", {
          method_name: mailResult.data.method,
          error_message: mailResult.data.error.message,
        });
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table .v-data-table__wrapper table .v-data-table-header tr th {
  height: 48px !important;
  background-color: #f8f8f8 !important;
  padding: 0 8px;
}

::v-deep .v-data-table .v-data-table__wrapper table tbody tr td {
  height: 56px !important;
  padding: 0 8px;
}

::v-deep .v-data-table__wrapper table thead tr th:first-child,
::v-deep .v-data-table__wrapper table tbody tr td:first-child {
  padding-left: 16px !important;
}

::v-deep .v-data-table__wrapper table thead tr th:last-child,
::v-deep .v-data-table__wrapper table tbody tr td:last-child {
  padding-right: 16px !important;
}
</style>
